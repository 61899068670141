
export default class SubsheetClass {



    constructor(data){
        // this.tableData = data.tableData
        // this.subsheetDataRows = $('<div>')
        // this.subsheetDataCols = $('<div>')
    }

    addTopButton(obj){

        $('.modal-header #attach').html(obj)
    }

}
