
export default class EventLogClass {


    main
    modal
    eventHandlers = []

    constructor(){

    }

    init(){

        this.main = $('<div>')

        this.modal = new CS.Modal({
            title: 'Event Log',
            body: this.main
        })
        this.startListening()

        this.eventHandlers.push((a,b)=>{

            this.main.append(
                $('<div>').html(b.txt)
            )

        })
    }

    show(){


        this.modal.show()

    }

    handleEvent(eventName, eventData) {

        this.eventHandlers.forEach(eventHandler => {
            eventHandler(eventName, eventData)
        })

    }

    startListening() {
        window.Echo.channel('maintenanceChannel')
            .listenToAll((a,b) => { this.handleEvent(a,b) })
    }



}
