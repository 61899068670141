import currency from "currency.js";
// import {showProgressBar, createProgressBar} from './common';
import {over, toInteger, uniqueId} from "lodash";
import {initValidations, resetFormInputs} from "./changeTracker";
import {createFromInput} from "./html/forms";
import {handleResponse, makeCall, showSuccess} from "../calcsys";


let modalObj
let modalDialog
let uploadToast
let invalidFields = []

let modalWidth = 1900;
let ctx = false;
let tableData = [];
let layout = [];
// let modalHeight = 400;

let subsheetContent = false;
let currentSubsheet = false;
let currentData = false;
let currentObject = false;
let subsheetDataRows = false;

let links = false;
let role = false;
let override = [];
let supportingData = [];

export function initSubsheetsModal(){
    modalObj = $('#CSSubsheetModal')[0];
    $(modalObj).on('show.bs.modal', function (e) {
        $('.modal-dialog', modalObj).css('width', modalWidth);
    });

    $(modalObj).on('hide.bs.modal', function (e) {
        unloadSubsheets(0)
    });

    modalDialog = new window.Modal(modalObj, {})


    $('.btn-save', modalObj).on('click',save);
    $('#close', modalObj).on('click',closeSubsheet);
}

export function openSubsheetModal(type, opts = []) {
    modalWidth = opts.width ? opts.width : modalWidth;
    modalDialog.show()
    $('.modal-title', modalObj).html(type);

}
function unloadSubsheets(data){
    CS.currentContextID('app')
    console.log('unload ss')

    if(CS.getUnsavedChanges() > 0){
        CS.confirm('Save before leaving?', () => {
            save()
            CS.clearTrackedChanges()
            if(data) loadSubsheet(data); else if(data === 0) closeSubsheet()
        }, () => {
            CS.clearTrackedChanges()
            if(data) loadSubsheet(data); else if(data === 0) closeSubsheet()
        })
        return false
    }
    return true
}

export function reloadSubsheet(uid){
        loadSubsheet(currentData)
}
export function closeSubsheet(){
    console.log('close ss')
    if(!unloadSubsheets()) return

    modalDialog.hide()
    ctx = $('#'+CS.currentContextID('CSSubsheetModal'))

    $('.modal-body', ctx).html('-')
    currentData = false
    subsheetDataRows = false
    if(currentObject){
        currentObject = currentObject.close()
    }
}

export function loadSubsheet(data){
    if(!unloadSubsheets(data)) return

    CS.newSubsheet()

    openSubsheetModal('Loading...')

    ctx = $('#'+CS.currentContextID('CSSubsheetModal'))
    $('.modal-body', ctx).html(CS.renderSpinner())

    $(' .extraInputs').html('')
    currentData = data
    subsheetDataRows = $('<div>').addClass('subsheetDataRows')


    CS.makeCall({
        url:route('subsheet.open'),
        data: data

    }, function (r){
        currentSubsheet = r.subsheet
        $('.subsheetTitle').html(r.data.subsheetTitle)

        supportingData = r.data.supportingData ?? []

        if(r.subsheet.name === 'Invoice')
        {
            supportingData['customers'] = r.data.customerData
            // $('.modal-body', ctx).html(CS.createInvoiceBaselineHTML())
            // CS.initInvoices(r)
            // CS.loadInvoice(r.data)
            CS.newInvoice()
                .init(r,ctx)

            CS.initValidations()
            return;
        }
        if(r.subsheet.name === 'Quote')
        {
            CS.newSubsheet(r)

            CS.newQuote()
                .init(r)

            return;
        }
        if(r.subsheet.name === 'Drawings')
        {
            currentObject = CS.Drawings.init(r.data)
            console.log('load drw ss')

            return;
        }
        if(r.subsheet.name === 'Timesheet')
        {
            CS.Timesheet.init(r.data)
            return;
        }
        if(r.subsheet.name === 'AddBid')
        {
            CS.AddBid.init(r.data)
            return;
        }
        if(r.subsheet.name === 'Estimate')
        {
            CS.Estimates.init(r.data)
            return;
        }

        role = r.data.role


        layout = r.data.layout
        tableData = r.data.tableData

        links = r.data.links
        override = r.data.override


        let layoutContent = $('<div>').addClass('container-fluid')
        let layoutContentRow = $('<div>').addClass('row')

        layoutContent.append(layoutContentRow)

        loadLinks();

        $(layout).each(function (){
            let c = $('<div>').addClass(this.class)
            if(this.type == 'dataView'){
                $(currentSubsheet.data.rows).each(function (){
                    c.append(createOutstandingCommData(this))
                })

            }
            if(this.type === 'map'){
                let mapElement = $('<div id="map">')
                c.append(mapElement)

                let addr = currentSubsheet.data.value ?? 'Toronto'

                CS.addToObserveList({
                    callback: function(){
                        CS.Map.loadGoogleMapsApi().then(function(googleMaps) {
                            CS.Map.setGoogleMapsApi(googleMaps)
                            CS.Map.geocode({address: addr},undefined,mapElement[0])
                            // CS.Map.initMap()
                            setTimeout(()=>{
                                $('#address').val(currentSubsheet.data.value ?? '').focus()

                            },500)
                        });
                    },
                    element: mapElement[0],
                })
            }
            if(this.type === 'input'){
                c.addClass('inputContainer')
                for(let x1 in tableData){
                    let td = tableData[x1]
                    let cont = $('<div>').addClass('row')

                    for(let x2 in td){
                        let t = td[x2]
                        cont.append(createFromInput(t))
                    }

                    c.append(cont)
                }
                let saveBtn = $('<span>')
                    .addClass('btn btn-success')
                    .on('click', save)
                    .html('Save')

                if(this.withSave !== '0')
                    c.append(saveBtn)
            }
            if(this.type === 'data'){
                let content = $('<div>')
                    .addClass('subsheetContainer sticky-top')
                // .addClass(currentSubsheet)
                // .attr('id', currentSubsheet)

                let dataForm = $('<div>')
                //     createForm({
                //     action: route('subsheet.save'),
                //     fields: formFields,
                // })
                content.append(dataForm)

                subsheetContent = $('<div>')
                    .addClass('subsheetContent')
                    .append(createHeader(tableData))


                subsheetContent.append(subsheetDataRows)
                $(r.data.rows).each(function (){
                    let row = createRow(this,tableData)

                    subsheetDataRows.append(row)
                })
                subsheetContent.append(createFooter(tableData))
                dataForm.append(subsheetContent)



                c.append(content)
            }
            if(this.type === 'images'){
                let imageContainer = $('<div>').addClass('imageContainer');

                $(r.data.imageFile).each(function()
                {
                    $(imageContainer).append(imageFile_HTML(this))
                })
                $(imageContainer).append(fileUploadForm_HTML())

                c.append(imageContainer)
            }
            layoutContentRow.append(c)
        })
        $('.modal-body', ctx).html(layoutContent)

        if(r.data.rows && r.data.rows.length === 0)
            addRow()

        loadExtraInputs(r.data.extras)
        updateSums();
    })
}

export function setCurrentSubsheet(subsheet){
    currentSubsheet = subsheet
}

export function getCurrentSubsheet(){
    return currentSubsheet
}

export function getSupportingData(){
    return supportingData
}

export function createHeader(tableData){
    let out = $('<div>')
        .addClass('rowContainer row sticky-top')

    $(tableData).each(function (){
        let cell = $('<div>')
            .append($('<div>').html(this.title))
            .addClass(this.class)
        if(this.subTitle)
            cell.append($('<div>').addClass('badge bg-dark').html(this.subTitle))

        out.append(cell)
    })

    return out;
}



export function createFooter(){
    let out = $('<div>')
        .addClass('rowContainer sticky-bottom footer-row bg-white')

    let addBtn = $('<span>')
        .addClass('btn-add-row row')
        .on('click', addRow)
        .html('<i class="fa-solid fa-plus col">Add Row</i>')

    let sumRow = $('<div>')
        .addClass('sumRowContainer row border-top border-dark ')


    $(tableData).each(function (){
        let cell = $('<div>')
            // .append($('<div>').html(this.title))
            .addClass(this.class)
        if(this.type == 'currency') {
            let x = $('<div>').addClass('total_'+this.name)
            cell.append(x)
        }
        sumRow.append(cell)

    })

    out.append(addBtn)
    out.append(sumRow)

    return out;
}

function addRow(){
    subsheetDataRows.append(createRow([],tableData))
}

export function createRow(data){
    let out = $('<div>')
        .addClass('rowContainer row dataRow')

    $(tableData).each(function (){
        let c = createCol(data, this)
        out.append(c)
    })


    return out
}

function createCol(data, tableData ){
   return CS.createCol(data, tableData)
}

function onFileUploadComplete(xhr) {
    CS.handleResponse(xhr.responseJSON)
}

export function attachFileHandle(out, complete){

    let notif
    out.attr('enctype', 'multipart/form-data')
        .addClass('subsheetImageForm')
    $(out).ajaxForm({
        // iframe: true,
        data: { subSheetID: currentSubsheet.id },
        beforeSerialize: function($form, options) {
            $('[name="subSheetID"]', $form).val(() => { console.log('XY??',CS.getCurrentSubsheet().id );return CS.getCurrentSubsheet().id } )
        },
        beforeSend: function () {


            // var percentage = '0'
            notif = CS.showProgressBar()
        },
        uploadProgress: function (event, position, total, percentage) {
            $('.progress .progress-bar').css("width", percentage+'%', function() {
                return $(this).attr("aria-valuenow", percentage) + "%"
            })
            if(percentage === 100){
                notif.hide()
                notif = CS.showSuccess('File uploaded successfully. Processing...')
            }
        },
        complete: (xhr) => {
            if(complete)
                complete(xhr)
            else
                onFileUploadComplete(xhr)

            notif.hide()

        }
    })

    return out
}

export function createForm(data){
    let out = $('<form>')
        .attr('action', data.action)
        .attr('method', "POST")

        .addClass('ajaxForm sticky-top')


    if(data.files) {
        out = attachFileHandle(out)
    }else{
        out.addClass('subsheetForm')
        .on('submit', CS.handleFormSubmit)
    }

    $(data.fields).each(function (){
        let inp = $('<input>')
            .attr('type','hidden')
            .val(this.value)
            .attr('name', this.name)
            .attr('id', 'i_'+this.name)

        if(this.type){
            inp.attr('type', this.type)

            out.append($('<label>').html(this.title))
        }
        out.append(inp)
    })

    return out
}

export function addUploadedFiles(files){
    let form = $('.subsheetImageForm')
    let cont = form.parent()
    form.resetForm()

    $(files).each(function (){
        cont.append(CS.singleFile_HTML(this))

        cont.append(
            $('<input type="hidden">').attr({value: this.id, name: 'file['+this.id+']'})
        )
    })
}

export function addSubsheetImages(newImages){
    let imageContainer = $('.imageContainer')[0]
    $('.subsheetImageForm').remove()
    $(newImages).each(function()
    {
        $(imageContainer).append(imageFile_HTML(this))
    })
    $(imageContainer).append(fileUploadForm_HTML())
}

function loadLinks(){
    $('#attach').html('')
    if(role == 'parent'){
        let menu = $('<ul class="navbar-nav ms-auto">')
        let menuItem = $('<li class="nav-item dropdown">')
        let link = $('<a class=" dropdown-toggle btn btn-info btn-sm " href="#" v-pre>')
            .attr('data-bs-toggle', "dropdown")
            .attr('aria-haspopup', "true")
            .attr('aria-expanded', "false")
            .attr('role', 'button')
            .html('<i className="fa-solid fa-plus"></i>Links (<span id="numLinkedItems">'+links.children.length+'</span>)<span className="caret"></span>')

        let menuItems = $('<div>')
            .addClass('dropdown-menu dropdown-menu-right menu-v2 small-menu')
            .attr('aria-labelledby', 'navbarDropdown')


        $(links.children).each(function (){
            let child = this;
            let childItems = $('<span>')
                .addClass('dropdown-item ')
                .html(this.created_at)
                .on('click', function (){
                    loadSubsheet({colID: currentData.colID, rowID: child.id})
                })

            menuItems.append(childItems)
        })

        let newChild = $('<span>')
            .addClass('dropdown-item')
            .on('click', newLinked)
            .html('Add Refund')

        menu.append(menuItem)
        menuItem.append(link)
        menuItem.append(menuItems)
        menuItems.append(newChild)
        $('#attach').html(menu)

    }else if(role === 'child'){
        let menu = $('<span class="btn btn-info btn-sm">')
            .html('View Parent')
            .on('click', function (){
                loadSubsheet(links.parent)
            })
        $('#attach').html(menu)
    }
}


function loadExtraInputs(inputs){

    for(let k in inputs){
        let t = inputs[k]
        let out = CS.createInputFromColumn(t, override)
        $('.extraInputs').append(out)
    }

}

export function updateTaxes($this){
    let thisValue = currency($this.val())
    if(!$('.vendorTaxRate').val()) return;
    let taxRate = $('.vendorTaxRate').val().replace('%','')


    let tax = thisValue.multiply(taxRate/100)
    let total = thisValue.add(tax)
    $this.parents('.row').first().find('[name="tax"]').val(tax.format())
    $this.parents('.row').first().find('[name="costWithTax"]').val(total.format())
}
export function getTableData(){
    return tableData
}
export function updateSums(){
    $(CS.getTableData()).each(function (){
        let tData = this;
        let a = currency(0);
        $('[data-name="'+this.name+'"]').each(function (){
            let n = currency($(this).val())
            a = a.add(n)
        })
        $('.total_'+tData.name).html(a.format())
    })

    let sumAmount = currency(0);
    $('.dataRow').each(function(){
        let qty = $('.calc_qty', this).val()
        let rate = $('.calc_rate', this).val()
        let out = '';
        if(!qty || !rate){

        }else{

            let curr = currency(rate)
            let x = curr.multiply(qty)

            out = x.format()
            $('.calc_amount', this).val(out)
        }
        sumAmount = sumAmount.add(out)
    })

    $('input.total').val(sumAmount.format())
    $('h1.subTotal').html(sumAmount.format())
    $('span.subTotal').html(sumAmount.format())

    $('input.taxTotal').val(sumAmount.multiply(0.13).format())

    $('h1.total, span.total').html(sumAmount.multiply(1.13).format())

    $('.box-sum').each(function() {
        let sumAmount = currency(0);

        console.log('zz', this)
        // $('.dataRow', this).each(function(){
            $('input.countSum', this).each(function (){
                sumAmount = sumAmount.add($(this).val())
                console.log('xx', $(this).val(), sumAmount)
            })
        // })
        $('.box-sum-out', this).html(sumAmount.format())

    })

}


export function isSubsheetDataValid(){
    if(CS.getInvalidFields().length > 0){
        CS.notif('danger', 'You have '+CS.getInvalidFields().length+' invalid fields')
        return false;
    }
    return true;
}

function save(){
    if(!isSubsheetDataValid()) {
        return
    }

    if( currentSubsheet.name === "Invoice"){
        CS.Invoice.save()
        CS.closeSubsheet()
        return
    }
    if( currentSubsheet.name === "Drawings"){
        CS.Drawings.save()
        return
    }
    if( currentSubsheet.name === "Quote"){
        CS.Quote.save()
        return
    }
    if( currentSubsheet.name === "Timesheet"){
        CS.Timesheet.save()
        return
    }
    if( currentSubsheet.name === "AddBid"){
        CS.AddBid.save()
        return
    }
    // if( currentSubsheet.name === "Estimate"){
    //     CS.Estimates.save()
    //     return;
    // }
    uploadToast = CS.notif('warning', 'Saving')

    let data = CS.collectData(ctx)
    let send = data.inputs
    send.subSheetID = currentSubsheet.id

    let cb = false;

    if( currentSubsheet.name === "ProjectAddress" || currentSubsheet.name === "Address"){

        send.mapData = {
            'address': CS.Map.getGoogleMapData().address_components,
            'placeID': CS.Map.getGoogleMapData().placeID,
        }
        cb = handleResponse


    }
    else if(currentSubsheet.name === "DaysOutstanding" ){
        cb = (r) => {
            if(r.success[0] === 'Saved'){
                reloadSubsheet()
            }
        }



    }else{
        cb = (r) => {
            if(r.success && r.success[0] === 'Saved'){
                CS.resetFormInputs()
                CS.clearTrackedChanges()
                uploadToast.hide()
                showSuccess('Saved')
            }

            CS.handleResponse(r)
        }

        send.data = data.rowData
        send.colID = currentSubsheet.colID
    }


    // console.log('data-send??',data,send,currentSubsheet)

    CS.ajaxCall({
        url: route('subsheet.save'),
        data: send,
        dataType: 'json',
    },cb)

}

function newLinked(){
    let d = currentData
    d.newLink = d.rowID
    d.taxRate = $('.extraInputs .vendorTaxRate').val()
    d.rowID = null
    loadSubsheet(d)
}

function uniqid(prefix = "", random = false) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
}


function imageFile_HTML(image){
    let img = $('<img>')
        .attr('src', '/images/'+image.id)
        .addClass('mw-100')
        .on('dblclick', function (){
            CS.startPhotoEditor(this,image.id)

        })

    return img
}


export function singleFile_HTML(image){
    let img = $('<div>')
        .append($('<i class="fa-solid fa-file-lines"></i>'))
        .append($('<span class=""></span>').html(image.name))

    return img
}



function fileUploadForm_HTML(){
    return CS.fileUploadForm_html( [
        {name: 'subSheetID', value: currentSubsheet.id},
        {name: 'colID', value: currentSubsheet.colID},
    ])
}

function createOutstandingCommData(data){

    let cont = $(`
<div class="card my-2">
    <div class="card-header">
        <div class="row">
            <div class="col" id="start"></div>
            <div class="col text-end" id="end"></div>
        </div>
    </div>
    <div class="card-body"></div>
    <div class="card-footer">
        <div class="hstack gap-5">
            <div class="" id="attachments"></div>
            <div class=" ms-auto"><sup>Type:</sup> <span id="type"></span></div>
            <div class=""><sup>Next Date Of Communication:</sup> <span id="nextDate"></span></div>
        </div>
    </div>
</div>
`)

    $('.card-header #start', cont).html(data.by)
    $('.card-header #end', cont).html(data.date)
    $('.card-body', cont).html(data.message)
    $('.card-footer #nextDate', cont).html(data.nextDate)
    $('.card-footer #type', cont).html(data.type)
    $(data.attachments).each(function (k){
        let l = $('<a>')
            .addClass('btn btn-info')
            .html('Attachment '+(k+1))
            .attr('href', '/images/'+toInteger(this))
            .attr('target', 'new')
        $('.card-footer #attachments', cont).html(l)
    })


    return cont
//
//         < div
//     className = "content-data-invoice" >
//         < div
//     className = "text-data-invoice" >
//         < div
//     className = "name-date-exit" >
//         < div
//     className = "name-text-exit" >
//         Roman
//     Ohnevyy
//     < /div>
//     <div className="date-text-exit">
//         14-Dec-2022, Wednesday, 21:58
//     </div>
// </div>
//     <div className="details-exit-invoice">
//         <pre className="details-text-exit">test</pre>
//         <div className="border-for-details"></div>
//     </div>
//
// </div>
//     <div className="data-under-exit">
//         <div className="type-nextData-under">
//             <div className="type-under-exit">
//                 <div className="type-text-under">
//                     Type:
//                 </div>
//                 <div className="type-text-nearby">
//                     Cell Phone
//                 </div>
//             </div>
//             <div className="nextData-under-exit">
//                 <div className="nextData-text-under">
//                     Next Date of communication:
//                 </div>
//                 <div className="nextData-text-nearby">
//                     17-Dec-2022
//                 </div>
//             </div>
//         </div>
//
//         <div className="attachFile-under-exit">
//             <div className="attach-under-text">
//
//
//             </div>
//
//         </div>
//
//     </div>
// </div>
//

}



//
//
//
//
//
//
//
// function Adjustments_addRow(){
//     $('#Adjustments .dataContainer').append(Adjustments_rowHtml())
//     setTimeout(function(){
//         datepicker($('.datePicker:last-child')[0], CS.dpOptions)
//     }, 500);
// }
//
//
//
// function Receipt_addRow(){
//     $('.dataContainer').append(Receipt_rowHtml())
// }
//
//
//
//
//
//
// function Receipt_rowHtml(rowData = []){
//
//     let projectNum = rowData[3] ??''
//     let type = rowData[2]??''
//     let cost = rowData[1]??''
//     let descr = rowData[0]??''
//     let delButton = $('<div>')
//         // .data('dismiss', 'Modal')
//         .addClass('')
//         .html('<i class="fa-solid fa-delete-left"></i>')
//         .on('click',function () {
//             $(this).parent().parent().remove();
//         });
//
//     let costInput = $('<input>')
//         .attr('name', 'cost[]')
//         .attr('placeholder', '1-100')
//         .addClass('form-control countSum form-control-sm')
//         .on('keyup', doKeyUp)
//         .val(currency(cost).format())
//         .on('blur', function () {
//             let cost = currency($(this).val())
//             // let tax = cost.multiply(0.07).format();
//             // let total = cost.add(tax).format()
//             // $(this).parents('.rowContainer').find('.taxContainer').html(tax)
//             // $(this).parents('.rowContainer').find('.costWithTaxContainer').html(total)
//             $(this).val(currency($(this).val()).format())
//         })
//
//         .on('focus', function () {
//             $(this).trigger('select')
//         });
//
//     let costAmount = currency(cost)
//     let taxAmount = costAmount.multiply(0.07).format();
//     let totalAmount = costAmount.add(taxAmount).format()
//
//     let newRowObject = $(`
//         <div class="rowContainer row">
//             <div class="col-1 projectNumContainer">
//                 <input class="form-control form-control-sm " autocomplete="off" type="text" value="`+projectNum+`" name="projectNum[]" placeholder="Project">
//             </div>
//             <div class="col-1 typeContainer">
//                 <select name="type[]" class="form-control form-control-sm">
//                     <option `+(type=='Materials'?'selected':'')+` value='Materials'>Materials</option>
//                     <option `+(type=='Consumables'?'selected':'')+` value='Consumables'>Consumables</option>
//                     <option `+(type=='Tools'?'selected':'')+` value='Tools'>Tools</option>
//                 </select>
//             </div>
//
//             <div class="col">
//                 <textarea class="form-control form-control-sm " autocomplete="off" type="text" name="descr[]" placeholder="Details" oninput="CS.auto_grow(this)">`+descr+`</textarea>
//             </div>
//
//             <div class="col-1 costContainer"></div>
//             <div class="col-1 taxContainer">`+taxAmount+`</div>
//             <div class="col-1 costWithTaxContainer">`+totalAmount+`</div>
//             <div class="col-1 btnContainer"></div>
//         </div>
//         `)
//
//     costInput.trigger('blur')
//     $('.btnContainer', newRowObject).append(delButton)
//     $('.costContainer', newRowObject).append(costInput)
//
//     return newRowObject
// }
//
// function Adjustments_rowHtml(cost = '', descr = '', descr2 = '', date = ''){
//     let id = uniqid()
//     let delButton = $('<button>')
//         .data('dismiss', 'Modal')
//         .addClass('removeRow')
//         .html('<i class="fa-solid fa-delete-left"></i>')
//         .on('click',function () {
//             $(this).parent().remove();
//         });
//
//     let costInput = $('<input>')
//         .attr('name', 'cost[]')
//         .attr('placeholder', '1-100')
//         .addClass('form-control countSum form-control-sm')
//         .on('keyup', doKeyUp)
//         .val(currency(cost).format())
//         .on('blur', function () {
//             let sel = $(this).parent().parent().find('select')[0];
//             if (sel.value !== 'other' && sel.value !== 'work') {
//                 if (this.value > 0) this.value *= -1;
//             }
//             $(this).val(currency($(this).val()).format())
//         });
//     let dateInput = $('<input>')
//         .attr('name', 'date[]')
//         .attr('placeholder', 'Date')
//         .attr('autocomplete', 'off')
//         .attr('id', id)
//         .val(date)
//         .addClass('datePicker form-control form-control-sm')
//
//
//     let newRowObject = $(`
//         <div class="rowContainer row"><div class="col">
//             <div class="content-date-back">
//             </div>
//             <div class="content-descr-back">
//                 <select name="descr[]" class="form-control form-control-sm">
//                     <option `+(descr=='damage'?'selected':'')+` value='damage'>Damage Backcharge</option>
//                     <option `+(descr=='scope'?'selected':'')+` value='scope'>Scope of Work Reduction</option>
//                     <option `+(descr=='work'?'selected':'')+` value='work'>Extra Work</option>
//                     <option `+(descr=='other'?'selected':'')+` value='other'>Other</option>
//                 </select>
//             </div>
//             <div class="content-descr2-back">
//                 <input class="form-control form-control-sm " autocomplete="off" type="text" value="`+descr2+`" name="descr2[]" placeholder="Details">
//             </div>
//             <div class="content-cost-back">
//             </div>
//         </div>
//         </div>
//         `)
//
//     $(newRowObject).append(delButton)
//     $('.content-cost-back', newRowObject).append(costInput)
//     $('.content-date-back', newRowObject).append(dateInput)
//     setTimeout(function(){
//         datepicker('#'+id, CS.dpOptions)
//     }, 500);
//     return newRowObject
// }
