

export default class ModalClass {


    main

    constructor(p){
        let params = {...[],...p}
        let m = $('<div>').addClass('modal ')
            .attr('id', params.id)

        let md = $('<div>').addClass('modal-dialog modal-sm')


        let mc = $('<div>').addClass('modal-content')


        let mh = $('<div>').addClass('modal-header')

        let mt = $('<h5>').addClass('modal-title')
            .html(params.title)

        let close = $('<button>')
            .addClass('btn-close')
            .attr('aria-label', 'Close')
            .attr('type', 'button')
            .attr('data-bs-dismiss', 'modal')

        let mb = $('<div>').addClass('modal-body p-2')
            .html(params.body)

        let mf = $('<div>').addClass('modal-footer')


        m.append(md)
        md.append(mc)
        mc.append(mh,mb,mf)
        mh.prepend(mt,close)

        this.main = new window.Modal(m, {})


    }

    show(){

        this.main.show()
    }

}

/*
<div class="modal fade" tabindex="-1" id="CSModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body"></div>
            <div class="modal-footer">
{{--                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>--}}
{{--                <button type="button" class="btn btn-primary">Save changes</button>--}}
            </div>
        </div>
    </div>
</div>

 */
