import {uniqueId} from "lodash";

export default class SyncClass {


    main
    cData


    constructor() {

    }

    init(data){
        console.log('init sync ', data)
        this.main = $('#SyncContainer .SyncMain')

        this.main.html('Loading...')
        this.cData = data

        let tabs = [
            {title: 'General', content: this.makeGenTab()},

        ]
        // for (const sheetID in data.info) {
        //     tabs.push({
        //         title: data.sheets[sheetID].fullName,
        //         content: CS.tabs_html([
        //             {title: 'New Rows', content: this.makeNewRowTable(data.newData[sheetID], sheetID)},
        //             {title: 'Existing Rows', content: this.makeTable(data.info[sheetID])},
        //             ]
        //
        //         )
        //     })
        // }

        this.main.html(CS.tabs_html(tabs))
    }

    makeGenTab(){
        let out = $('<div>').addClass('row')
        let left = $('<div>').addClass('col')
        let right = $('<div>').addClass('col')
        out.append(left,right)

        right.append(
            $('<div>')
                .addClass('btn btn-danger')
                .html('Pull From Server')
                .on('click', () => {
                    CS.confirm('Are you sure? This will erase all offline changes and copy everything from online', () => {
                        CS.EventLog.show()
                        CS.ajaxCall({
                            url: route('tools-sync-pull'),
                            method: 'POST',
                            success: (d) => {
                                CS.handleResponse(d)
                            }
                        })
                    })

                })
        )
        let i = this.cData.info
        left.append(
            $('<div>').html('New Rows: '+i.newRows),
            $('<div>').html('Cell Changes: '+i.newRows),
            $('<div>').html('Subsheets: '+i.newRows),
            $('<div>').html('Files: '+i.newRows),
            $('<div>')
                .addClass('btn btn-warning')
                .html('Push Local Changes To Server')
                .on('click', () => {



                    CS.confirm('Are you sure? ', () => {
                        CS.EventLog.show()
                        CS.ajaxCall({
                            url: route('tools-sync-push'),
                            method: 'POST',
                            success: (d) => {
                                CS.handleResponse(d)
                            }
                        })
                    })

                })
        )

        return out
    }

    makeNewRowTable(data,sheetID){
        let out = $('<div>')

        for(let rowID in data) {
            let row = $('<div>').addClass('row')
            let r = data[rowID]
            console.log('mnrxxt', rowID,r)



            let title = $('<div>').addClass('col')

            for(let colID in r) {
                console.log('mnrt', colID,r)
                title.append(data[rowID][colID].formated)
            }
            let buttons = $('<div>').addClass('col')

            let view = $('<div>').addClass('btn btn-small btn-warning').html('View')
                .on('click', () => {
                    window.open(route('page.open',this.cData.sheets[sheetID].shortName)+'?rid='+rowID, '_blank');
                })

            buttons.append(view)

            row.append(title, buttons)
            out.append(row)
        }


        return out
    }

    makeTable(data){
        let colTpl = [
            {class: 'col-1', title: 'Row #'},
            {class: 'col', title: 'Column Name'},
            {class: 'col-2', title: 'Local Value'},
            {class: 'col-2', title: 'Server Value'},
            {class: 'col-1', title: '-'},
        ]
        let out = $('<div>')

        let row = $('<div>').addClass('row')
        for (const tpl in colTpl) {
            row.append($('<div>').addClass(colTpl[tpl].class).html(colTpl[tpl].title))

        }
        out.append(row)
        for (const rowID in data) {
            for (const colID in data[rowID]) {
                let row = $('<div>').addClass('row')
                let d = [rowID, this.cData.cols[colID].fullName, data[rowID][colID].formated]

                // row.append(
                //     $('<div>').addClass('col-1').html(colID),
                //     $('<div>').addClass('col').html(this.cData.cols[colID].fullName),
                //     $('<div>').addClass('col').html(data[rowID][colID]),
                // )
                for (const tpl in colTpl) {
                    if(d[tpl]) {
                        let x = $('<div>')
                            .addClass(colTpl[tpl].class)
                            .html(d[tpl])

                        row.append(x)
                    }
                }




                let pushBtn = $('<span>')


                let pullBtn = $('<span>')
                    .addClass('btn btn-success btn-sm')
                    .html('Get Server Value')
                    .on('click', () => {
                        CS.ajaxCall({
                            url: route('tools-sync-cellVal'),
                            method: 'POST',
                            data: {
                                rowID: rowID,
                                colID: colID,
                            },
                            success: (d) => {
                                remoteVal.html(d.formated)
                                pushBtn
                                    .addClass('btn btn-success btn-sm')
                                    .html('Push Change')
                                    .on('click', () => {
                                    // CS.ajaxCall({
                                    //     url: '/api/s/push',
                                    //     method: 'POST',
                                    //     data: {
                                    //         colID: colID,
                                    //         rowID: rowID,
                                    //         value: data[rowID][colID].val,
                                    //         user: CS.currentUser().id,
                                    //     },
                                    //     success: (d) => {
                                    //         CS.handleResponse(d)
                                    //         row.hide()
                                    //     }
                                    // })
                                    CS.ajaxCall({
                                        url: route('tools-sync-update'),
                                        method: 'POST',
                                        data: {
                                            id: data[rowID][colID].cID,
                                            val: data[rowID][colID].val
                                        },
                                        success: (d) => {
                                            row.hide()
                                            CS.handleResponse(d)
                                        }
                                    })


                                })
                            }})
                    })

                let remoteVal = $('<span>').html(pullBtn)

                row.append($('<div>')
                    .addClass(colTpl[3].class)
                    .append(remoteVal)
                )
                row.append($('<div>')
                    .addClass(colTpl[4].class)
                    .append(pushBtn)
                )



                out.append(row)
            }
        }
        return out
    }

    updateCode(){
        CS.confirm('Are you sure you want to update the system?', ()=>{
            CS.EventLog.show()
            CS.ajaxCall({
                'url': route('tools-sync-fsync'),
                data: []
            }, CS.handleResponse)
        })
    }
}
